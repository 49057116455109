/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header/Header"
import Footer from '../Footer/Footer';
import "./layout.css"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
        }
      }
    }
  `)

  return (
    <div className="layout-container" style={{ height: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div style={{ height: '100%', flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
        {children}
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
