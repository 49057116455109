import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Row, Col } from 'react-flexbox-grid';
import './Header.css';

const Header = ({ siteTitle }) => {
  const [isActive, setIsActive] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  return(
    <header id="header">
      <Row 
        style={{borderBottom: '2px solid #f5f5f5', margin: 0, borderTop: '5px solid rgb(36,53,101)'}}
        middle="xs"
        center="xs">
        <Col xs={12} sm={12} md={12} lg={11}>
          <div>
            <nav className="navbar is-white" role="navigation" aria-label="main navigation">
              <div className="navbar-brand" style={{height: 70}}>
                <Link to="/" className="navbar-item brand-image" style={{padding: 0, marginLeft: 10}}>
                  <div style={{width: '100%', height: '100%'}}>
                    <img
                      style={{width: '110px', minHeight: '60px', margin: '3px 0 0 0'}}
                      src={require('../../images/rs-security-logo.png')} 
                      alt="RS-Securitu-Logo"/>
                  </div>
                </Link>
            
                <a onClick={() => setIsActive(!isActive) }
                  style={{ height: 'auto', width: 60}}
                  href={null} 
                  role="button" 
                  className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
                  aria-label="menu" 
                  aria-expanded="false" data-target="navbarItems">
                  <span style={{width: 20}} aria-hidden="true"></span>
                  <span style={{width: 20}} aria-hidden="true"></span>
                  <span style={{width: 20}} aria-hidden="true"></span>
                </a>
              </div>
              <div id="navbarItems" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className="navbar-end">
                  <Link to="/" className="navbar-item no-focus">Inicio</Link>
                  <div className={`navbar-item has-dropdown ${isDropdown ? "is-active" : ""} `} >
                    <a href={null}  className="navbar-link no-focus" onClick={() => setIsDropdown(!isDropdown)}>Servicios</a>
                    <div className="navbar-dropdown">
                      <Link to="/#services" className="navbar-item" onClick={() => setIsDropdown(!isDropdown)}>Instalación Cámaras de Seguridad</Link>
                      <Link to="/#alarms" className="navbar-item" onClick={() => setIsDropdown(!isDropdown)}>Instalación Alarmas y Sirenas</Link>
                      <Link to="/#electric-fence" className="navbar-item" onClick={() => setIsDropdown(!isDropdown)}>Instalación Cercos Eléctricos</Link>
                    </div>
                  </div>
                  <Link to="/contact" className="navbar-item no-focus">Contacto</Link>
                </div>
              </div>
            </nav>
          </div>
        </Col>
      </Row>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
