import React from "react"
import { Row, Col } from 'react-flexbox-grid';
import { Link } from "gatsby"
import './Footer.css';

const Footer = ({ props }) => (
   <footer id="footer" >
      <div style={{ backgroundColor: '#111111', padding: '60px 0 20px 0'}}>
         <Row style={{justifyContent: 'center'}}>
            <Col xs={11}>
               <Row around="xs">
                  <Col xs={12} md={3} lg={3} style={{marginBottom: 30}}>
                     <h5 className="title is-5 has-text-white" style={{marginBottom: 10}}>Contacto</h5>
                     <p style={{color: 'rgba(255,255,255,0.66)', paddingBottom: 13, fontSize: 14}}>
                        <span className="has-text-white">Teléfono</span>
                        <br></br> +56 96473 8608
                     </p>
                     <p style={{color: 'rgba(255,255,255,0.66)', paddingBottom: 13, fontSize: 14}}>
                        <span className="has-text-white">Email</span>
                        <br></br> rs.securitty@gmail.com
                     </p>
                     <p style={{color: 'rgba(255,255,255,0.66)', paddingBottom: 13, fontSize: 14}}>
                        <span className="has-text-white">Dirección</span>
                        <br></br> Eleuterio Ramirez Nº 825, Santiago de Chile
                     </p>
                  </Col>
                  <Col xs={12} md={3} lg={3} style={{marginBottom: 30}}>
                     <h5 className="title is-5 has-text-white" style={{marginBottom: 10}}>Soluciones de Vigilancia</h5>
                     <p style={{color: 'rgba(255,255,255,0.66)', paddingBottom: 13, fontSize: 14}}>
                        Domicilios Exteriores
                        <br></br> Domicilios Interiores
                        <br></br> Oficinas
                        <br></br> Bodegas 
                        <br></br> Industrias
                        <br></br> Edificios
                        <br></br> Sensores de Movimiento
                        <br></br> Alarmas y Sirenas
                     </p>
                  </Col>
                  <Col xs={12} md={3} lg={3} style={{marginBottom: 30, padding: 0}}>
                     <Row around="xs" style={{margin: 0}}>
                        <Col xs={12} style={{marginBottom: 20}}>
                           <h5 className="title is-5 has-text-white" style={{marginBottom: 10}}>RS Security</h5>
                           <p style={{color: 'rgba(255,255,255,0.66)', paddingBottom: 13, fontSize: 14}}>
                              <Link to="/#header" className="has-text-white">Inicio</Link><br></br>
                              <Link to="/#services"  className="has-text-white">Servicios</Link><br></br>
                              <Link to="/contact" className="has-text-white">Contacto</Link>
                           </p>
                        </Col>
                        <Col xs={12}>
                           <h5 className="title is-5 has-text-white" style={{marginBottom: 20}}>Encuentranos</h5>
                           <Col xs={6} sm={4} md={12} lg={10}  style={{padding: 0}}>
                              <Row style={{margin: 0}}>
                                 <Col xs={4} lg={3} style={{padding: 0}}>
                                    <a className="rss-link rss-facebook" href="https://www.facebook.com/rssecurity/">
                                       <span className="icon is-medium icon-span">
                                          <i className="fab fa-facebook-square fa-2x"/>
                                       </span>
                                    </a>
                                 </Col>
                                 <Col xs={4} lg={3} style={{padding: 0}}>
                                    <a className="rss-link rss-instagram" href="https://www.instagram.com/securityland6/?hl=es-la">
                                       <span className="icon is-medium icon-span">
                                          <i className="fab fa-instagram-square fa-2x"/>
                                       </span>
                                    </a>
                                 </Col>
                                 <Col xs={4} lg={3} style={{padding: 0}}>
                                    <a className="rss-link rss-whatsapp" href="https://api.whatsapp.com/send?phone=56964738608&text=Estoy%20interesado%20en%20los%20servicios%20de%20RS%20Security.">
                                       <span className="icon is-medium icon-span">
                                          <i className="fab fa-whatsapp-square fa-2x"></i>
                                       </span>
                                    </a>
                                 </Col>
                              </Row>
                           </Col>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
            <Col xs={12}>
               <div className="content has-text-centered" style={{ padding: '10px 0 5px 0'}}>
                  <p style={{fontSize: 12}} className="has-text-white">
                     © 2020 RS Security, Todos los derechos reservados.
                  </p>
               </div>
            </Col>
         </Row>
      </div>
   </footer>
)

export default Footer;